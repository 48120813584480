import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 className="px-2 sm:text-center">Trailguide How-To</h1>
    <br />
    <div className="leading-9 md:text-xl">
      <p><a parentName="p" {...{
          "href": "/howto/install"
        }}>{`How do I install Trailguide as an App?`}</a></p>
      <p><a parentName="p" {...{
          "href": "/howto/gps-on-iphone"
        }}>{`How do I get the GPS working on iPhone?`}</a></p>
      <p><a parentName="p" {...{
          "href": "/howto/navigation"
        }}>{`How do I use Trailguide for navigation?`}</a></p>
      <p><a parentName="p" {...{
          "href": "/howto/mtbmap"
        }}>{`How do I use MTBmap as a base map?`}</a></p>
      <p><a parentName="p" {...{
          "href": "/howto/garmin-import"
        }}>{`How do I import Trailguide trails onto my Garmin device?`}</a></p>
      <p><a parentName="p" {...{
          "href": "/howto/add-trail"
        }}>{`How do I add a trail on Trailguide?`}</a></p>
      <p><a parentName="p" {...{
          "href": "/howto/content-quality"
        }}>{`How do I maintain the quality of content?`}</a></p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      